// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const IframePage = () => {
  return (
    <div style={{ position:"fixed", top:0, left:0, bottom:0, right:0, width:"100%", height:"100%", border:"none", margin:0, padding:0, overflow:"hidden", "zIndex":999999 }}>
      <iframe
        title="external-content"
        src={`https://fun-resource-333527.framer.app${window.location.pathname}`}
        style={{ width: '100%', height: '100%', border: 'none' }}
      />
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
      <Route path="*" element={<IframePage />} />
      </Routes>
    </Router>
  );
};

export default App;
